

#hero {
	position: relative;
	background: linear-gradient(0deg, rgba($primary-color, 1), rgba($primary-color, 1)), url("../images/leonardo-drawing.jpg");
	background-blend-mode: multiply;
	background-repeat: no-repeat;
	background-size: cover;

	.c-grid {
		display: grid;
		grid-template-columns: 1fr;
		grid-gap: 40px;

		@include mq(m) {
			grid-template-columns: 1fr 1fr;
		}
	}

	.hero-title {
		font-size: var(--fluid-jumbo);
		margin-bottom: 1rem;
	}

	.hero-title-wrap {
		max-width: 450px;
		width: 100%;
		margin-top: auto;
		margin-bottom: auto;
	}

	.image-cell {
		position: relative;
		padding-top: 85%;

		@include mq(m) {
			padding-top: 75%;
		}

		.hero-image {
			position: absolute;
			top: 0;
			width: 100%;
			height: 100%;
			object-fit: cover;
		}

		.hero-card {
			position: absolute;
			top: 0;
			right: 2em;
			background-color: $light-gray;
			padding: 1.5em;
			padding-top: 2.5em;
			padding-right: 2em;

			h5 {
				color: $primary-color;
				font-weight: 500;
			}

			a {
				color: $primary-color;
				font-weight: 500;

				&:hover {
					color: white;
				}

				&:active {
					color: white;
				}
			}
		}
	}
}

#events {
	background: $tertiary-color;
	position: relative;
	overflow: hidden;

	.fancy-pulley {
		position: absolute;
		top: 0;
		bottom: 0;
		left: -20%;
		filter: contrast(1.5) brightness(.6);

		img {
			padding: 1rem 0;
			height: auto;
			max-height: 100%;
			opacity: .8;
		}

	}

	h2 {
		font-weight: 200;
	}


	.c-grid {
		display: grid;
		grid-template-columns: 1fr;
		grid-gap: 40px;

		@include mq(m) {
			grid-template-columns: 1fr 1fr;
		}

		@include mq(l) {
			grid-template-columns: 1fr 1fr 1fr;
		}
	}
}


#get-involved {
	background: url("../images/pulley-rectangle-drawing.jpg");
	background-blend-mode: multiply;
	background-repeat: no-repeat;
	background-size: cover;

	.s-inner-wrap {
		overflow: visible;
	}

	.c-grid {
		display: grid;
		grid-template-columns: 1fr;
		grid-gap: 40px;

		@include mq(m) {
			grid-template-columns: 1fr 1fr;
		}
	}
	.overflow-title {
		position: relative;
		z-index: 1;
		background: $light-gray;
		color: $primary-color;
		padding: .8em .5em;
		font-weight: 200;
		margin-top: 2rem;
		@include mq(m) {
			margin-right: -80px;
		}

		&:after {
			content: '';
			position: absolute;
			top: 0;
			right: 0;
			width: 1500px;
			height: 100%;
			z-index: -1;
			background-color: $light-gray;
		}
	}

	.info-wrap {
		margin-top: auto;
		.description {
			max-width: 325px;
		}

		a {
			margin-top: 2.5rem;
		}
	}
	.image-cell {
		position: relative;

		.volunteer-image {
			width: 100%;
		}

		.volunteer-card {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			right: 0;
			background-color: $primary-color;
			padding: 1.5em;
			padding-top: 2.5em;
			padding-right: 2em;

			h5 {
				font-weight: 500;
			}

			a {
				font-weight: 500;
			}
		}
	}
}


