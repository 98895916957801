.c-grid {
	display: grid;

	.cell {
		display: flex;
		flex-flow: column nowrap;
		height: auto;
	}
}



.f-grid {
	display: flex;

	.cell {
		display: flex;
		flex-flow: column nowrap;
		height: auto;
	}
}
