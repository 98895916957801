[data-better-menu] {
	// Better Menu a Variables
	--bm-a-pad-top: 0.4rem;
	--bm-a-pad-left: 0.5rem;
	--bm-a-pad-right: 0.5rem;
	--bm-a-pad-bottom: 0.4rem;
	--bm-a-color: white;
	--bm-a-weight: bold;
	--bm-a-size: var(--fluid-body-sm);
	--bm-a-color-hover: #{$secondary-color};
	--bm-a-color-active: #{$secondary-color-dark};
	--bm-a-transition: all 0.3s ease;

	// Submenus
	--bm-submenu-width: 225px;
	--bm-submenu-text-alignment: left;
	--bm-submenu-item-alignment: flex-start;
	--bm-submenu-hor-pad: .75rem .5rem;
	// Vertical menu
	--bm-vert-submenu-pad: .75rem .5rem;

	// Dropdown arrow
	--bm-dropdown-arrow-height: 15px;
	--bm-dropdown-arrow-width: 18px;
	--bm-dropdown-arrow-padding: 8px;
	--bm-dropdown-arrow-color: #{$secondary-color};
}

[data-better-modal] {
	--bm-transition-duration: 0.4s;
	--bm-transition-function: ease;

	--bm-overlay-color: rgba(0, 0, 0, 0.4);
}

[data-bm-button] {
	--bm-button-padding-tb: 10px;
	--bm-button-padding-lr: 10px;
	--bm-button-background-color: transparent;
	--bm-button-border: unset;
	--bm-button-border-radius: 0;

	--bm-toggle-line-width: 35px;
	--bm-toggle-line-height: 5px;
	--bm-toggle-line-gap: 5px;
	--bm-toggle-line-border-radius: 10px;
	--bm-toggle-line-color: #{$primary-color};
}
