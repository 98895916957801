/**
 * --- Font-face setters ---
 * Supports Local and external font foundries
 * Prefer using .woff files when using local fonts
 **/
@import url("https://use.typekit.net/ayg4zgn.css");

// Settings for font-face
$header-font-name: "dharma-gothic-e";
$body-font-name: "neuzeit-grotesk";
$header-font-family: $header-font-name, -apple-system, "Ubuntu", sans-serif;
$body-font-family: $body-font-name, -apple-system, "Ubuntu", sans-serif;

@mixin general-typography-setter() {
	h1, h2, h3, h4, h5, h6 {
		font-family: $header-font-family;
		font-weight: $header-font-weight;
		line-height: $header-line-height;
		margin-bottom: $header-margin-bottom;
		color: $header-color;
		text-transform: uppercase;
		font-smooth: always;
	}

	html {
		font-weight: $body-font-weight;
		font-family: $body-font-family;
		line-height: $body-line-height;
		color: $white;
		font-smooth: always;
	}

	p {
		margin-bottom: $paragraph-margin-bottom;
	}
}

/** --- General typography settings --- **/
$header-line-height: 1;
$header-margin-bottom: 0.5rem;
$header-font-weight: 300;
$header-color: $white;

$body-font-weight: 400;
$body-line-height: 1.2;
$body-font-color: $white;
$paragraph-margin-bottom: 1rem;
