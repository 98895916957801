$white: #ffffff;
$black: #000000;
$dark-gray: #212121;
$gray: #6A6A6A;
$light-gray: #E5E3D2;

$primary-color: #053D85;
$secondary-color: #D01B31;
$tertiary-color: #041E40;
$quaternary-color: #1196F7;
$quinary-color: #F8E425;
