.body-controller {
	overflow-x: hidden;
	display: flex;
	flex-flow: column nowrap;
	min-height: 100vh;

	footer {
		margin-top: auto;
	}
}


body {
	display: flex;
	flex-flow: column nowrap;
	margin: 0;
	background-color: $primary-color;

	&:not([data-s-loaded]) {
		* {
			transition: none !important;
		}
	}
}


// Make sure all columns are flex for easy 100% height divs
.uagb-column__inner-wrap {
	display: flex;
	flex-flow: column nowrap;
}


.s-section {
	&.s-constrain {
		.s-inner-wrap {
			width: 1000px;
		}
	}

	&.page-section {
		.s-inner-wrap {
			display: block;
		}
	}
}
