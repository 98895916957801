/**
 * Link styling
 * Links can get pretty fancy so the setting partials do not get involved
 **/

a {
	color: $light-gray;
	transition: color .3s ease;

	&:hover, &:focus {
		color: darken($light-gray, 5%)
	}

	&:active {
		color: darken($light-gray, 10%);
	}
}
