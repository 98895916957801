#footer {
	background-color: $tertiary-color;
	font-size: var(--fluid-body-xs);

	a {
		font-size: var(--fluid-body-xs);
		text-decoration: none;
	}
	.s-inner-wrap {
		padding-top: 20px;
		padding-bottom: 15px;

		width: 320px;


		@include mq(m) {
			width: 600px;
		}

		@include mq(l) {
			width: $global-width;
		}
	}

	.f-grid {
		display: flex;
		flex-flow: row wrap;
		align-content: center;
		justify-content: space-around;
		width: 100%;

		.cell {
			padding: 20px 15px;
			width: 100%;
		}

		.logo-cell {
			padding-left: 0;
			padding-right: 0;
			width: auto;
		}

		.social-cell {
			width: auto;
		}


		@include mq(m) {
			.cell {
				width: 50%;
			}
		}

		@include mq(l) {
			flex-flow: row nowrap;
			.cell {
				width: auto;
			}
		}
	}

	.logo-cell {
		img {
			width: 60px;
		}


		@include mq(l) {

			img {
				width: 100px;
			}
		}
	}

	.social-cell {
		.footer-icon-row {
			display: flex;
			flex-flow: row nowrap;
			margin-top: -.75em;
			margin-right: -.75em;
			margin-left: -.75em;
			margin-bottom: .4em;
			.footer-icon {
				display: flex;
				padding: .75em;
				color: white;

				&:hover, &:active, &:focus {
					color: $secondary-color;
				}

				&:active {
					color: $secondary-color-dark;
				}

				svg {
					height: 1.2em;
				}
			}
		}
	}

	.footer-link {
		display: flex;
		flex-flow: row nowrap;
		align-items: center;
		margin-bottom: .5em;
		color: white;
		font-weight: bold;
		font-size: var(--fluid-body-xs);

		&:hover, &:active, &:focus {
			color: $secondary-color;
		}

		&:active {
			color: $secondary-color-dark;
		}
	}


	.address-cell {
		color: white;
		font-size: var(--fluid-body-xs);
		a {
			color: white;
			font-weight: 400;
			margin-bottom: 1rem;
		}

		address {
			font-weight: lighter;
			font-style: normal;
		}
	}

	.hours-cell {
		color: white;
		.hours-flex-wrap {
			display: flex;
			flex-flow: column nowrap;

			.line {
				display: flex;
				flex-flow: row nowrap;
				justify-content: space-between;
				align-items: center;
				margin-bottom: .4em;
			}

			.day {
				margin-right: 1.5rem;
			}

			.hours {
				flex-shrink: 0;
			}
		}
	}

	.menu-cell {
		.menu {
			padding-left: 0;
			list-style: none;
			display: flex;
			flex-flow: row nowrap;
			margin-top: 0;
			// li group
			> li {
				display: flex;
				flex-flow: column nowrap;
				padding: 0 20px;
				a {
					font-weight: 600;
					color: white;

					&:hover, &:active, &:focus {
						color: $secondary-color;
					}

					&:active {
						color: $secondary-color-dark;
					}
				}

				// children of group
				ul {
					list-style: none;
					padding: 5px 0;
					li {
						padding: 5px 0;
						a {
							text-decoration: none;
							font-weight: 400;
							font-size: var(--fluid-body-xs);
						}
					}
				}
			}
		}
	}

	.links-cell {
		.affiliation-links {
			list-style: none;
			margin-top: -.3rem;
			padding-left: 0;
			li {
				margin-bottom: 1rem;

				a {
					display: flex;
					font-size: var(--fluid-body-xs);
					color: white;
					text-decoration: none;
					padding: .3rem;

					&:hover, &:active, &:focus {
						color: $secondary-color;
					}

					&:active {
						color: $secondary-color-dark;
					}

				}
			}
		}
	}

	.footer-copyright-row {
		background-color: $tertiary-color;
		padding: 10px;
		display: flex;
		flex-flow: column nowrap;
		justify-content: space-around;
		align-content: center;
		align-items: center;

		.copyright-text,
		a {
			font-size: var(--fluid-body-xs);
			color: white;
		}

		a {
			text-decoration: underline;
		}

		.copyright-text {
			font-weight: 300;
			text-align: center;
		}

		.policy-link {
			padding: 5px 10px;
			text-align: center;

			a {
				&:hover,
				&:focus {
					color: $tertiary-color-light;
				}

				&:active {
					color: $tertiary-color;
				}

				&[data-s-active-anchor] {
					color: $tertiary-color;
				}
			}
		}

		.policy-links {
			display: flex;
			flex-flow: column nowrap;
			justify-content: space-around;
			list-style: none;
			max-width: 100%;
			padding-left: 0;
			align-self: center;
			margin: 0;

			@include mq(m) {
				flex-flow: row wrap;
				width: 800px;
			}
		}
	}

	#menu-footer-bold-links {
		padding-left: 20px;
	}
}
