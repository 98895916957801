#admission {
	color: white;

	.f-grid {
		display: flex;
		flex-flow: row wrap;
		margin: -1rem;

		.cell {
			padding: 1rem;

			@include mq(m) {
				padding: 2rem;
			}

			@include mq(l) {
				padding: 2.5rem;
			}
		}

		.hours-cell {
			width: max-content;
			flex-grow: 1;
		}

		.admission-cell {
			width: 600px;
			max-width: 100%;
			@include mq(l) {
				flex-basis: 50%;
			}
		}
	}

	.hours-card {
		background-color: $quaternary-color;
		padding: 1.5rem;

		@include mq(m) {
			padding: 2rem;
		}

		.hour-footer {
			font-weight: 300;
			font-size: var(--fluid-h6);
		}
	}
	.hours-flex-wrap {
		display: flex;
		flex-flow: column nowrap;
		font-family: $header-font-family;
		font-size: var(--fluid-h5);
		width: max-content;

		.line {
			display: flex;
			flex-flow: row nowrap;
			justify-content: space-between;
			align-items: center;
			margin-bottom: .6em;
		}

		.day {
			margin-right: 1.5rem;
			font-weight: 300;
			color: $light-gray;
		}
	}


	.box-wrap {
		display: flex;
		flex-flow: row wrap;
		align-content: center;
		justify-content: center;
		.box {
			display: flex;
			flex-flow: column nowrap;
			text-align: center;
			padding: 1.2rem;
			padding-bottom: 2rem;
			flex-basis: 50%;

			@include mq(m) {
				padding: 1.5rem;
				flex-basis: 50%;
			}

			@include mq(l) {
				flex-basis: 33.333%;
			}

			.box-title, .box-cost {
				font-family: $header-font-family;
			}

			.box-title {
				margin-bottom: 1.5rem;
				color: $light-gray;
				font-weight: 300;
				@include fluid-prop(font-size, 22px, 30px);
				text-transform: uppercase;
			}

			.box-cost {
				margin-top: auto;
				flex-shrink: 0;

				@include fluid-prop(font-size, 30px, 80px);
			}


			&:nth-child(3n + 1) {
				background-color: $tertiary-color;
			}

			&:nth-child(3n + 2) {
				background-color: $quaternary-color;
			}

			&:nth-child(3n + 3) {
				background-color: $gray;
			}
		}
	}
}


#find-us {
	position: relative;
	background-color: $tertiary-color;

	.s-inner-wrap {
		padding-top: 50px;
		padding-bottom: 50px;
		overflow: visible;
	}

	.c-grid {
		display: grid;
		grid-template-columns: 1fr;
		grid-row-gap: 40px;
		grid-column-gap: 60px;
		@include mq(m) {
			grid-template-columns: 1fr 1fr;
		}
	}


	.info-cell {
		justify-content: center;
		.info-title {
			margin-bottom: 2rem;
		}
	}

	.image-cell {
		@include mq(m) {
			img, iframe {
				margin-top: -75px;
				margin-bottom: -75px;
			}
		}
	}
}

#visit-footer {
	background: linear-gradient(0deg, rgba($primary-color, 1), rgba($primary-color, 1)), url("../images/get-involved-bg.jpg");
	background-blend-mode: multiply;
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;


	.s-inner-wrap {
		padding-top: 125px;
		padding-bottom: 125px;
	}

	.footer-title {
		margin-bottom: 2rem;
		text-align: center;
	}
	.box-wrap {
		display: flex;
		flex-flow: row wrap;
		justify-content: center;
		align-items: center;



		.box {
			padding: 1rem;
			margin: .5rem;
			min-width: 250px;

			h5 {
				margin-top: 1rem;
			}
			&:nth-child(3n + 1) {
				background-color: $tertiary-color;
			}

			&:nth-child(3n + 2) {
				background-color: $quaternary-color;
			}

			&:nth-child(3n + 3) {
				background-color: $gray;
			}
		}
	}
}
