

#directors {
	.s-inner-wrap {
		width: $global-width-md;
	}
	.f-grid {
		display: flex;
		flex-flow: row wrap;
		justify-content: center;

		.cell {
			display: flex;
			flex-flow: column nowrap;
			width: 100%;
			margin: .4rem;
			flex-grow: 0;

			@include mq(m) {
				width: calc(50% - .8rem);
			}

			@include mq(l) {
				width: calc(33.33% - .8rem);
			}
		}

		.director-single:nth-child(2n + 1) {
			background-color: $gray;

			.name-header {
				background-color: darken($gray, 10%);

				&:after {
					background-color: $primary-color-dark;
				}
			}
		}

		.director-single:nth-child(2n + 2) {
			background-color: $tertiary-color;

			.name-header {
				background-color: darken($tertiary-color, 5%);

				&:after {
					background-color: $light-gray;

				}
			}
		}
	}

	.director-single {
		height: auto;
		flex-grow: 1;

		.name-header {
			padding: 1rem;
		}

		.director-info {
			padding: 1.4rem 1rem;
		}

		.name-header {
			position: relative;
			&:after {
				content: '';
				position: absolute;
				bottom: -10px;
				left: -8px;
				height: 20px;
				width: 65%;
			}
		}

		.director-info {
			> p {
				margin-top: 0;
				margin-bottom: 0;
			}
		}

	}
}

.director-info-card {
	background-color: $quaternary-color;
	padding: 2.5rem 1rem;
	text-align: center;
	max-width: 100%;
	width: 600px;
	margin: 4rem auto 0;

	h4 {
		margin-bottom: 1.5rem;
	}
}
