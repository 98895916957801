#page-404 {
	.s-inner-wrap {
		overflow: visible;
	}
}

.card-404 {
	display: grid;
	grid-gap: 30px;
	grid-template-columns: 1fr;
	margin-top: 20px;
	@include mq(m) {
		grid-template-columns: .8fr 1.2fr;
	}


	.fancy-wrap {
		.fancy-title {
			font-family: $header-font-family;
			font-size: calc(var(--fluid-jumbo) * 1.2);
			font-weight: 300;
			margin-bottom: 0;
			line-height: .6;
			padding-left: .8rem;
			color: $light-gray;
		}
		.fancy-overflow {
			position: relative;
			padding: .8em 0;
			color: $primary-color;

			@include mq(m) {
				font-size: calc(var(--fluid-h2) * .8);
			}

			&:after {
				content: '';
				position: absolute;
				top: 0;
				bottom: 0;
				right: -60px;
				width: 2000px;
				height: 100%;
				z-index: -1;
				background-color: $light-gray;
			}
		}

	}

	.links-404 {
		display: flex;
		flex-flow: row wrap;
		justify-content: center;
		align-items: center;
		a {
			margin: 1rem;
		}
	}
}
