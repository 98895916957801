#exhibits {

	.s-inner-wrap {
		overflow: visible;
	}
	.exhibits-list {
		// Control the flow
		.exhibit-single:nth-child(even) {
			flex-direction: row-reverse;
			margin: auto;

			.fancy-header {
				&:after {
					left: -20px;
					width: calc(100% + 80px);

					@include mq(m) {
						left: unset;
						right: -100px;
						width: calc(100% + 150px);
					}

					@include mq(l) {
						width: calc(100% + 200px);
					}
				}
			}

			.image-cell {
				.image-wrap {
					&:after {
						left: 0;
					}
				}

				.image-info-box {
					right: 0;
				}
			}
		}

		.exhibit-single:nth-child(odd) {
			flex-direction: row;
			margin: auto;
			.fancy-header {
				&:after {
					left: -100px;
				}
			}

			.image-cell {
				.image-wrap {
					&:after {
						right: 0;
					}

					.image-info-box {
						left: 0;
					}
				}
			}
		}

		// control the color

		.exhibit-single:nth-child(3n+1) {
			.date {
				color: $light-gray;
			}

			.fancy-header {
				color: $light-gray;

				.header-color {
					color: $primary-color;
				}
			}

			.image-wrap {
				color: $light-gray;
			}
		}

		.exhibit-single:nth-child(3n+2) {
			.date {
				color: $quaternary-color;
			}

			.fancy-header {
				position: relative;
				color: $quaternary-color;

				.header-color {
					color: white;
				}
			}

			.image-wrap {
				color: $quaternary-color;
			}
		}

		.exhibit-single:nth-child(3n+3) {
			.date {
				color: $tertiary-color;
			}

			.fancy-header {
				position: relative;
				color: $tertiary-color;

				.header-color {
					color: white;
				}
			}

			.image-wrap {
				color: $tertiary-color;
			}
		}

	}


	.exhibit-single {
		display: flex;
		flex-flow: row wrap;
		padding-bottom: 2rem;
		max-width: 50ch;


		@include mq(l) {
			max-width: 100%;
		}

		.cell {
			height: 100%;
			width: 100%;
			padding: 20px;

			@include mq(l) {
				width: 50%;
			}
		}
		.info-wrap {
			.date {
				font-family: $header-font-family;
				font-size: var(--fluid-jumbo);
				font-weight: 200;
				color: currentColor;
				margin-bottom: 0;
				line-height: .7;
			}

			.fancy-header {
				position: relative;
				z-index: 1;
				background-color: currentColor;
				padding: .8em .5em;
				padding-left: 0;
				font-weight: 200;


				&:after {
					content: '';
					position: absolute;
					top: 0;
					bottom: 0;
					width: calc(100% + 40px);
					height: 100%;
					z-index: -1;
					background-color: currentColor;

					@include mq(m) {
						width: calc(100% + 150px);
					}

					@include mq(l) {
						width: calc(100% + 200px);
					}
				}
			}

			.description {
				margin-top: 2rem;
				width: 60ch;
				max-width: 100%;
			}
			.header-color {
				color: currentColor;
			}
		}

		.image-cell {
			.image-wrap {
				position: relative;
				padding-bottom: 100%;
				img {
					position: absolute;
					top: 0;
					left: 0;
					height: 100%;
					width: 100%;
					object-fit: cover;
					object-position: center;
				}

				&:after {
					content: '';
					position: absolute;
					z-index: 1;
					bottom: -12.5px;
					background-color: currentColor;
					height: 25px;
					width: 90%;
				}
			}

			.image-info-box {
				position: absolute;
				bottom: 30px;
				background-color: $light-gray;
				padding: 1rem;
				color: $tertiary-color;

				.image-date {
					color: $quaternary-color;
					font-family: $header-font-family;
					font-size: var(--fluid-h5);
				}
				.car-make {
					font-family: $header-font-family;
					font-size: var(--fluid-h6);
				}

				.driver-name {
					margin-top: .5rem;
					font-size: var(--fluid-body-sm);
					font-weight: 700;
				}

				.source {
					padding-top: .5rem;
					font-size: var(--fluid-body-sm);
					a {
						margin-top: .5rem;
						color: $black;
						font-weight: 600;

						&:hover {
							color: $secondary-color;
						}

						&:active {
							color: $secondary-color-dark;
						}
					}
				}
			}
		}
	}
}
