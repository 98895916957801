#featured-event {
	background-color: $tertiary-color;

	.c-grid {
		display: grid;
		grid-template-columns: 1fr;
		column-gap: 30px;
		row-gap: 0;

		@include mq(m) {
			grid-template-columns: 1fr 1fr;
		}
	}

	.featured-event-single {
		.image-cell {
			position: relative;
			width: 100%;
			transition: transform .3s ease;

			img {
				z-index: 0;
				width: 100%;
			}
			&:hover, &:focus, &:active {
				transform: scale(1.01);
			}
		}

		.fancy-header {
			position: relative;
			z-index: 2;
			background-color: $secondary-color;
			padding: 1.2rem 2rem;
			padding-left: 0;
			align-self: flex-start;
			margin-top: 1.5rem;
			color: white;
			font-family: $header-font-family;
			font-size: var(--fluid-h2);
			font-weight: 300;
			text-transform: uppercase;
			text-decoration: none;
			&:after {
				content: '';
				position: absolute;
				top: 0;
				bottom: 0;
				right: 0;
				width: calc(100% + 125px);
				height: 100%;
				background-color: $secondary-color;
				z-index: -1;
				transition: background .3s ease, transform .3s ease;
			}

			&:hover, &:active, &:focus {
				&:after {
					transform: translateX(15px);
				}
			}

			&:active {
				&:after {
					background-color: $secondary-color-dark;
				}
			}
		}

		.event-date {
			font-family: $header-font-family;
			font-size: var(--fluid-h4);
			border-bottom: 6px solid $primary-color;
			align-self: flex-start;
			padding-bottom: .3rem;
			margin: 1rem 0;
		}

		.fancy-btn {
			margin-top: 2rem;
			align-self: flex-start;
		}
	}
}



#events-loop {
	position: relative;
	overflow: hidden;
	flex-grow: 1;

	.fancy-pulley {
		position: absolute;
		top: 0;
		left: -20%;
		filter: saturate(.5) brightness(.8);
	}

	.event-loop-title {
		text-align: center;
		align-self: center;
		margin-bottom: 2rem;
	}


	.c-grid {
		display: grid;
		grid-template-columns: 1fr;
		gap: 40px;

		@include mq(m) {
			grid-template-columns: 1fr 1fr;
		}

		@include mq(l) {
			grid-template-columns: 1fr 1fr 1fr;
		}
	}
}


.event-card {
	display: flex;
	flex-flow: column nowrap;
	width: 100%;
	height: auto;
	flex-grow: 1;
	a {
		text-decoration: none;
	}


	.card-content {
		display: flex;
		flex-flow: column nowrap;
		padding-top: 1rem;
		padding-bottom: 1rem;
		flex-grow: 1;

		.card-excerpt {
			color: white;
			font-size: var(--fluid-body-sm);
		}
	}

	.card-date {
		font-family: $header-font-family;
		font-size: var(--fluid-h5);
		border-bottom: 6px solid $tertiary-color;
		align-self: flex-start;
		padding-bottom: .3rem;
		margin: .8rem 0;
	}

	.image {
		height: 215px;
		background-color: $gray;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center center;
		display: flex;
		justify-content: flex-end;
		flex-direction: column;
		transition: filter .3s ease;

		@include breakpoint(medium) {
			height: 225px;
		}
		@include breakpoint(large) {
			height: 250px;
		}

		&:hover, &:active, &:focus {
			.headline-text {
				background-color: $secondary-color-dark;
			}
		}

		&:active {
			filter: brightness(.8);
		}


		.headline-text {
			background-color: $secondary-color;
			color: $white;
			padding: 1rem 1rem 0.5rem;
			font-weight: 300;
			font-family: $header-font-family;
			font-size: var(--fluid-h4);
			text-transform: uppercase;
			min-width: 50%;
			align-self: flex-start;
			margin-bottom: .8em;
			transition: background-color .3s ease;

			&:hover, &:active, &:focus {
				background-color: $secondary-color-dark;
			}
		}
	}

	.card-excerpt {
		margin-bottom: 1rem;
	}

	.fancy-btn {
		margin-top: auto;
		margin-left: auto;
		align-self: flex-start;
	}
}


.no-event {
	display: flex;
	flex-flow: column nowrap;
}

.no-event-text {
	text-align: center;
	margin-top: 40px;
	position: relative;
	align-self: center;
	width: auto;

	&:after {
		content: '';
		position: absolute;
		bottom: -.2rem;
		left: 0;
		background-color: $quinary-color;
		width: 105%;
		height: 10px;
	}
}
