.single-event {
	.s-inner-wrap {
		padding-top: 20px;
	}

	.event-content {
		display: flex;
		flex-flow: column nowrap;
		width: 70ch;
		max-width: 100%;
		margin: 0 auto;
	}

	.go-back-btn {
		margin-bottom: 1.2rem;
		text-decoration: none;
		font-weight: bold;
		margin-left: -.5rem;

	}

	.single-event-title {
		width: 100%;
	}
	.date-tag {
		font-family: $header-font-family;
		font-size: var(--fluid-h5);
		border-bottom: 6px solid $tertiary-color;
		align-self: flex-start;
		padding-bottom: .3rem;
		margin: .8rem 0;
	}
	.venue-tag {
		font-family: $body-font-family;
		color: $light-gray;
		font-weight: bold;
		font-size: var(--fluid-h5)
	}
	.event-featured-image {
		margin-top: 1rem;
		margin-bottom: 1rem;
		align-self: center;
	}
	.additional-events {
		display: flex;
		flex-flow: row wrap;
		margin-top: 2rem;
		align-items: flex-start;
		justify-content: center;
		width: 100%;

		.cell {
			padding: .5rem;
			flex-basis: 100%;

			@include mq(m) {
				flex-basis: 50%;
			}

			@include mq(l) {
				flex-basis: 33.33%;
			}
		}
		h4 {
			width: 100%;
			margin-bottom: 1rem;
			text-align: center;
		}
	}
	.content {
		width: 100%;
	}
}
