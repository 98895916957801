.archive-grid {
	display: grid;

	grid-template-columns: 1fr;
	grid-gap: 20px;
	margin-left: 1rem;
	margin-right: 1rem;
	@include mq(m) {
		grid-template-columns: 1fr 1fr;
		margin-left: 2rem;
		margin-right: 2rem;
	}

	@include mq(l) {
		grid-template-columns: 1fr 1fr 1fr;
		margin-left: 4rem;
		margin-right: 4rem;
	}
}

.post-card {
	display: flex;
	flex-flow: column nowrap;
	background: $gray;

	.post-card-image {
		position: relative;
		padding-bottom: 80%;
		width: 100%;
		img {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			object-fit: cover;
			object-position: center center;
		}
	}

	.post-card-content {
		display: flex;
		flex-flow: column nowrap;
		padding: 1rem;
		flex-grow: 1;

	}

	.post-card-title {

	}

	.post-card-date {
		font-weight: 600;
		font-size: var(--fluid-body-sm);
	}

	.post-card-excerpt {
		margin-top: auto;
		font-size: var(--fluid-body-sm);
	}

	.post-card-link {
		margin-left: auto;
	}
}

.pagination {
	margin: 2rem auto;
	margin-bottom: 0;
	font-size: var(--fluid-h6);
	a {
		text-decoration: none;
		padding: .2rem;

		&:hover {
			color: red;
		}
	}

	.current {
		color: $quinary-color;
	}
}
