#membership-hero {
	position: relative;
	overflow: visible;
	z-index: 1;
	.s-inner-wrap {
		padding-top: $padding-c-tb-sm;
		overflow: visible;
	}
	.c-grid {
		display: grid;
		grid-template-columns: 1fr;
		grid-gap: 40px;

		@include mq(m) {
			grid-template-columns: 1fr 1fr;
		}
	}

	.info-cell {
		justify-content: center;

		.hero-desc {
			font-weight: 300;
		}
	}

	.image-cell {
		grid-row: 1;

		@include mq(m) {
			grid-row: unset;
		}
		.img-wrap {
			margin-top: -120px;
		}
	}
}


#donor-levels {


	// Variable styles
	.donor-level-single:nth-child(3n + 1) {
		background: url("../images/pulley-rectangle-drawing.jpg");
		background-blend-mode: multiply;
		background-repeat: no-repeat;
		background-position: center center;
		background-size: cover;


		.donor-level-header {
			color: $tertiary-color;

			.level-title {
				color: $tertiary-color;
			}

			&:after {
				background-color: $light-gray;
			}
		}

		.donor-level-blocks {
			.donor-block:nth-child(4n + 1) {
				background-color: $tertiary-color;
			}

			.donor-block:nth-child(4n + 2) {
				background-color: $quaternary-color;
			}
			.donor-block:nth-child(4n + 3) {
				background-color: $gray;
			}
			.donor-block:nth-child(4n + 4) {
				background-color: $dark-gray;
			}
		}

	}

	.donor-level-single:nth-child(3n + 2) {
		background: url("../images/pulley-dark-blue.png");

		background-repeat: no-repeat;
		background-position: right center;
		background-size: contain;


		.donor-level-header {
			&:after {
				background-color: $quaternary-color;
			}
		}

		.donor-level-blocks {
			.donor-block:nth-child(3n + 1) {
				background-color: $gray;
			}

			.donor-block:nth-child(3n + 2) {
				background-color: $tertiary-color;
			}
			.donor-block:nth-child(3n + 3) {
				background-color: $dark-gray;
			}
		}
	}

	.donor-level-single:nth-child(3n + 3) {
		background: linear-gradient(0deg, rgba($quaternary-color, .3), rgba($quaternary-color, .3)), url("../images/leonardo-drawing.jpg");
		background-blend-mode: multiply;
		background-repeat: no-repeat;
		background-position: center center;
		background-size: cover;


		.donor-level-header {
			&:after {
				background-color: $tertiary-color;
			}
		}


		.donor-level-blocks {
			.donor-block:nth-child(3n + 1) {
				background-color: $gray;
			}

			.donor-block:nth-child(3n + 2) {
				background-color: $quaternary-color;
			}
			.donor-block:nth-child(3n + 3) {
				background-color: $dark-gray;
			}
		}
	}


	// base styles
	.donor-level-single {
		.s-inner-wrap {
			overflow: visible;
			padding-left: $padding;

			@include mq(m) {
				padding-left: 50px;
			}
		}
		.donor-level-header {
			position: relative;
			align-self: flex-start;
			padding-top: 2rem;
			padding-bottom: 1rem;
			padding-right: 2.5rem;
			width: 550px;
			max-width: 100%;
			z-index: 1;

			.level-desc {
				font-weight: 300;
			}


			&:after {
				content: '';
				position: absolute;
				top: 0;
				right: 0;
				width: calc(100% + 500px);
				height: 100%;
				z-index: -1;
				background-color: $light-gray;
			}
		}

		.donor-level-blocks {
			position: relative;
			margin-top: 2rem;
			z-index: -1;
			display: flex;
			flex-flow: row wrap;
			justify-content: center;
			align-items: center;
			width: 100%;
			max-width: 100%;

			@include mq(m) {
				margin-top: -10px;
				width: max-content;
			}
		}

		.donor-block {
			height: auto;
			align-self: stretch;
			text-align: center;
			padding: 2rem 1rem;
			background-color: $tertiary-color;

			@include mq(m) {
				padding: 2.2rem 1.5rem;

			}
			.donor-block-title {
				margin-bottom: 1.5rem;
				color: $light-gray;
			}

			.donor-range {
				max-width: 100px;
				margin-bottom: .5rem;
				font-weight: 300;
			}
		}
	}
}

.embed-container { position: relative; padding-bottom: 56.25%; height: 0; overflow: hidden; max-width: 100%; }
.embed-container iframe, .embed-container object, .embed-container embed { position: absolute; top: 0; left: 0; width: 100%; height: 100%; }
