.page-header {
	background: linear-gradient(0deg, rgba($primary-color, 1), rgba($primary-color, 1)), url("../images/leonardo-drawing.jpg");
	background-blend-mode: multiply;
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;
	padding-top: 185px;
	padding-bottom: 40px;

	display: flex;
	justify-content: center;
	align-items: center;


	h1 {
		max-width: $global-width;
		text-align: center;
	}

	&.overflow-header {
		padding-bottom: 125px;
	}
}

.overflow-the-header {
	margin-top: -125px;

	.s-inner-wrap {
		padding-top: 0;
	}
}


.hero-padding {
	padding-top: 150px;
}


.header-nav {
	.sticky-top-row {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		transition: all .5s ease;
		transform: translateY(-100%);
		opacity: 0;
		z-index: 1;

		&[data-s-active] {
			transform: translateY(0);
			opacity: 1;
		}
	}
}
