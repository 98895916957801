.fluentform {
	form {
		.ff-btn {
			border-radius: 0;
			background-color: unset !important;
			border: unset !important;
			@extend .fancy-btn;
			font-size: var(--fluid-body);

			&:hover, &:active, &:focus {
				opacity: 1;
			}
		}

		.ff-el-form-control {
			border-radius: 0;
		}

	}

	textarea {
		font-family: $body-font-family;
	}
}

.gform_wrapper .gfield_required, .gform_wrapper.gravity-theme .ginput_product_price {
	color: #ffffff !important;
}