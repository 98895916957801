.header-nav {
	display: flex;
	flex-flow: column nowrap;
	width: 100%;
	z-index: 100;
	position: absolute;

	.header-row {
		display: flex;
		flex-flow: column nowrap;

		.header-row-inner-wrap {
			display: flex;
			flex-flow: row nowrap;
			justify-content: space-between;
			width: 100%;
			max-width: $global-width;
			margin: 0 auto;
			padding: 10px $padding;
		}

		.header-link {
			display: flex;
			align-items: center;
			color: $secondary-color;
			border: unset;
			background: unset;
			font-family: $body-font-family;
			font-size: var(--fluid-body-sm);
			font-weight: 700;
		}
	}

	.logo-container {
		display: flex;
		flex-flow: row nowrap;
		align-items: flex-start;
		text-decoration: none;


		.logo {
			height: 130px;
			margin-top: -10px;

			@include mq(m) {
				margin-top: -35px;
				height: 140px;
			}

			@include mq(l) {
				height: 170px;
			}
		}
		.logo-name {
			color: $quinary-color;
			margin-left: 1em;
			font-size: var(--fluid-h6);
			display: none;

			@include mq(m) {
				display: flex;
			}
		}
	}

	.top-row {
		background: $tertiary-color;
		.header-row-inner-wrap {
			padding: 0 0 0 10px;
			justify-content: flex-end;


			.header-link {
				padding: 10px 0;
				margin-left: .4em;
				transition: color .3s ease;
				display: flex;
				flex-flow: row nowrap;
				text-decoration: none;
				color: white;
				font-size: var(--fluid-body-sm);

				@include mq(s) {
					flex-flow: row nowrap;
					margin-left: 1em;
				}

				@include mq(m) {
					margin-left: 2em;
				}

				&:first-child {
					margin-left: 0;
				}

				&:hover, &:active, &:focus {
					color: $secondary-color;
				}
			}

			.header-bold-link {
				background-color: $secondary-color;
				padding-left: 2em;
				padding-right: 2em;
				transition: background-color .3s ease;

				&:hover, &:active, &:focus {
					color: white;
					background-color: $secondary-color-dark;
				}

				&:active {
					background-color: $secondary-color;
				}
			}
			button.header-link {
				cursor: pointer;
			}

			.callout-link {
				background-color: $primary-color;
				padding: 10px 14px 1em;
				margin-bottom: -8px;
				color: white;
				transition: background .3s ease;

				.icon {
					color: $secondary-color;
				}

				&:hover, &:active, &:focus {
					background-color: $primary-color-light;
					color: white !important;
				}
			}
			button {
				padding: 0;
			}
		}
	}
}

// Better menu mods

#desktop-nav {
	display: none;

	@include mq(l) {
		display: flex;
	}
}

#mobile-nav-button {
	display: flex;

	.toggle-line {
		background-color: white;
		border-radius: 0;
	}

	@include mq(l) {
		display: none;
	}
}


#navigation-modal {
	a {
		color: white;

		&:hover, &:focus {
			color: $secondary-color;
		}

		&:active {
			color: $secondary-color-dark;
		}
	}

	[data-bm-card] {
		width: 100%;
		background: $primary-color;

		.bm-card-inner {
			padding: 2em;

		}

		[data-bm-button] {
			position: absolute;
			top: 10px;
			right: 10px;

			color: white;
			margin-left: auto;
			padding: 1rem;
			font-weight: 500;
			cursor: pointer;

			svg {
				padding: 0.4rem 0.2rem;
			}
		}
	}
}
