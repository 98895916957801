/**
 * Buttons
 **/

.fancy-btn {
	position: relative;
	display: inline-flex;
	padding: .5em .5em .6em .1em;
	margin-bottom: 1em;
	font-weight: 500;
	color: white;
	text-decoration: none;
	z-index: 1;
	align-self: flex-start;

	&:after {
		content: "";
		position: absolute;
		bottom: 0;
		left: 0;
		height: 10px;
		background: $secondary-color;
		width: calc(100% + .5em);
		transition: all .3s ease;
		z-index: -1;
	}
	// hover states
	&:hover, &:focus, &:active {
		color: white;

		&:after {
			height: 100%;
		}
	}

	// active state - click
	&:active {
		color: white;

		&:after {
			background: $secondary-color-dark;
		}
	}
}


.wp-block-button {
	.wp-block-button__link {
		border-radius: 0;
		font-size: var(--fluid-body);
		background: unset;
		@extend .fancy-btn;
	}
}
